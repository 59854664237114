import React, { useState } from 'react';
import './BottomPanel.css';
import { Link } from 'react-router-dom';
import LoginPopUp from '../popups/LoginPopUp';

const BottomPanel = () => {

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    const toggleLoginModal = () => {
        setIsLoginModalOpen(!isLoginModalOpen);
        const navbarCollapse = document.getElementById('mainNav');
        if (navbarCollapse.classList.contains('show')) {
            navbarCollapse.classList.remove('show');
            navbarCollapse.classList.add('collapse'); // Manually collapse the navbar
        }

        const mainCollapsed = document.getElementById('mainCollapsed');
        if (!mainCollapsed.classList.contains('show')) {
            mainCollapsed.classList.add('collapsed');
        }

    };
    return (
        <div className="bottom-panel">
            <div className="panel-content">
                {/*<div className="image-section">
          <img src="/path-to-your-image" alt="Illustration of a window with a dog" />
        </div>*/}
                <div className="text-section">
                    <h2 className="offer-main-text">Your window is closing.</h2>
                    <p className="offer-description">
                        Get access to all premium article by subscribing to our plan. Cancel anytime.
                    </p>
                </div>
                <div className="cta-section">
                    <div className="d-flex justify-content-center">
                        {" "}
                        <Link
                            to="/subscription"
                            className="btn btn-default "
                        >
                            SUBSCRIBE
                        </Link>
                    </div>
                    <p className="sign-in">Already a subscriber?
                    {" "}
                    <div
                        onClick={toggleLoginModal}
                    >
                        <Link
                            to=""
                            className="header-login-btn d-md-block"
                        >
                            &nbsp;<u>Sign In</u>&nbsp;
                        </Link>
                    </div> </p>
                </div>
                {isLoginModalOpen && (
                    <div className="popUp" id="popUp">
                        <LoginPopUp closeModal={toggleLoginModal} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default BottomPanel;
