import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import { Footer, Header, Loading } from "../components";
import { Link, useParams } from "react-router-dom";
// import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { useAlert } from "react-alert";
import headerShapeRight from "../assets/images/header-shape-right.svg";
import headerShapeLeft from "../assets/images/header-shape-left.svg";
import plusIcon from "../assets/images/plus-icon.svg";
import { convertDateFormate, fixedHeader } from "../values/utils";
import ReadingProgressBar from "../components/core/ReadingProgressBar";
import ArticleType from "../components/article/articleType";
import PhotoEssayType from "../components/article/photoEssayType";
import { convertDataToSlides } from "../components/article/convertDataToSlides";
import Slider from "../components/slider/slider";
// gsap.registerPlugin(ScrollTrigger);

const Article = ({
  articleDSPCH,
  articleData,
  articleDataLoad,
  saveArticleDSPCH,
  saveArticleData,
  userData,
}) => {
  const [articleList, setArticleList] = useState([]);

  const { id } = useParams();
  const pageURL = window.location.href;
  const saveArticleDataRef = useRef(saveArticleData);
  const articleDataRef = useRef(articleData);
  const alert = useAlert();
  const [photoEssaySliderMode, setPhotoEssaySliderMode] = useState(false);

  useEffect(() => {
    getArticle();
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
    fixedHeader();

    // var singleArticle = gsap.timeline();
    // singleArticle.to(".article-progress-bar", {
    //   width: "100%",
    //   ease: "none",
    //   scrollTrigger: {
    //     trigger: ".article-single-container",
    //     start: "top top",
    //     end: "bottom bottom",
    //     scrub: true,
    //     markers: true,
    //   },
    // });
  }, []);

  useEffect(() => {
    if (saveArticleData !== saveArticleDataRef.current) {
      if (saveArticleData?.success === true) {
        alert.success(
          <div style={{ textTransform: "initial" }}>
            {saveArticleData?.message}
          </div>
        );
      } else {
        alert.error(
          <div style={{ textTransform: "initial" }}>
            {saveArticleData?.message}
          </div>
        );
      }
    }
  }, [saveArticleData, saveArticleDataRef]);

  useEffect(() => {
    if (articleData !== articleDataRef.current) {
      if (
        articleData?.success === true &&
        articleData?.data?.post_type === 3 &&
        articleData?.data?.episodes_data?.data?.length > 0
      ) {
        setArticleList(
          articleList.concat(articleData?.data?.episodes_data?.data)
        );
      } else {
        setArticleList([]);
      }
    }
  }, [articleData, articleDataRef]);

  const getArticle = () => {
    if (userData?.success === true) {
      articleDSPCH({ articleSlug: id, token: userData?.data?.token });
    } else {
      articleDSPCH({ articleSlug: id, token: "" });
    }
  };

  const saveArticle = (article_id) => {
    if (userData?.success === true) {
      saveArticleDSPCH({
        token: userData?.data?.token,
        article_id: article_id,
      });
    }
  };

  const onHandleLoadMore = (nextPageUrl) => {
    if (userData?.success === true) {
      articleDSPCH({
        articleSlug: id,
        token: userData?.data?.token,
        nextPageUrl: nextPageUrl,
      });
    } else {
      articleDSPCH({ articleSlug: id, token: "", nextPageUrl: nextPageUrl });
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          {articleData?.success === true && articleData?.data?.meta_title}
        </title>
        <meta
          name="description"
          content={
            articleData?.success === true && articleData?.data?.meta_description
          }
        />
      </Helmet>
      <Header />
      {/* <!--============================== Main Start ==============================--> */}
      {articleDataLoad ? (
        <Loading type={"spin"} color={"#000"} />
      ) : (
        <main id="main">
          {/* <!--============================== Content Start ==============================--> */}
          <>
            {(() => {

              if (
                articleData?.data?.post_type === 2 &&
                articleData?.data?.video_embed_code !== null
              ) {
                //VIDEO TYPE 
                //TODO: call the coponent when it can be tested out
                return (
                  <>
                    <div className="content-container pb-0">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="news-post-content text-center">
                              <h1>{articleData?.data?.title}</h1>
                              <h4>{articleData?.data?.sub_title}</h4>
                              <div className="news-post-meta d-flex justify-content-between">
                                <span className="news-post-cat">Video </span>
                                <div className="article-post-by">
                                  by <span>{articleData?.data?.author_detail?.name}{" "}</span>
                                </div>
                                <span className="news-post-date">
                                  {convertDateFormate(
                                    articleData?.data?.publish_date
                                  )}
                                </span>
                              </div>
                              <div className="post-category-heading">
                                <div className="pch-img">
                                  <img
                                    src={articleData?.data?.subject_image}
                                    alt=""
                                  />
                                </div>
                                <p>{articleData?.data?.subject_name}</p>
                              </div>
                              {userData?.success === true && (
                                <div className="news-post-save d-flex justify-content-center">
                                  <i
                                    className="fa fa-bookmark"
                                    aria-hidden="true"
                                    type="button"
                                    onClick={() =>
                                      saveArticle(articleData?.data?.id)
                                    }
                                  ></i>
                                  <p
                                    type="button"
                                    onClick={() =>
                                      saveArticle(articleData?.data?.id)
                                    }
                                    style={{ marginLeft: 10 }}
                                  >
                                    Save
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--============================== Content End ==============================--> */}
                    {/* <!--============================== Content Start ==============================--> */}
                    <div className="content-container  pt-0">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="video-block">
                              <div className="ratio ratio-16x9">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: articleData?.data?.video_embed_code,
                                  }}
                                />
                              </div>
                            </div>
                            {articleData?.data?.description === "" ? (
                              <div className="post-single block-text">
                                <p>{articleData?.data?.excerpt}</p>

                                <div className="d-flex justify-content-center paywall">
                                  {" "}
                                  <Link
                                    to="/subscription"
                                    className="btn btn-default "
                                  >
                                    SUBSCRIBE
                                  </Link>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="post-single block-text"
                                dangerouslySetInnerHTML={{
                                  __html: articleData?.data?.description,
                                }}
                              />
                            )}
                            <div className="cta-blog text-center">
                              <p>
                                The best of <span>Object Magazine,</span> in
                                your inbox.{" "}
                                <Link to="/newsletter">
                                  Sign up for our Newsletters now.
                                </Link>{" "}
                              </p>
                            </div>
                            {articleData?.data?.transcripts && (
                              <div className="simple-heading">
                                <h4>TRANSCRIPTS</h4>
                              </div>
                            )}
                            {articleData?.data?.transcripts && (
                              <div className="fixed-block-text-content">
                                <div className="fixed-block-text custom-scrollbar">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: articleData?.data?.transcripts,
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <ul className="share-post-list fixed d-none d-xl-flex flex-column">
                          <li>
                            <FacebookShareButton
                              url={pageURL}
                              quote={articleData?.data?.sub_title}
                              title={articleData?.data?.title}
                            >
                              <a href="" target="_blank">
                                <i
                                  className="fab fa-facebook-f"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </FacebookShareButton>
                          </li>
                          <li>
                            <TwitterShareButton
                              url={pageURL}
                              quote={articleData?.data?.sub_title}
                              title={articleData?.data?.title}
                            >
                              <a href="" target="_blank">
                                <i
                                  className="fab fa-twitter"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </TwitterShareButton>
                          </li>

                          <li>
                            <LinkedinShareButton
                              url={pageURL}
                              quote={articleData?.data?.sub_title}
                              title={articleData?.data?.title}
                            >
                              <a href="" target="_blank">
                                <i
                                  className="fab fa-linkedin-in"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </LinkedinShareButton>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <!--============================== Content End ==============================--> */}
                    {/* <!--============================== Content Start ==============================--> */}
                    <div className="content-container p-0">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="end-section">
                              <h5>
                                <span className="left">
                                  <img src={headerShapeRight} alt="" />
                                </span>
                                end
                                <span className="right">
                                  <img src={headerShapeLeft} alt="" />
                                </span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--============================== Content Start ==============================--> */}
                    <div className="content-container pb-5">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="post-author-container d-flex flex-wrap">
                              <div className="post-author-content">
                                <h5>Author</h5>
                                <div className="post-auther">
                                  <Link
                                    to={
                                      "/author/" +
                                      articleData?.data?.author_detail?.slug
                                    }
                                    className="post-auther-img"
                                  >
                                    <img
                                      src={
                                        articleData?.data?.author_detail?.image
                                      }
                                      alt=""
                                    />
                                  </Link>
                                  <div className="post-author-name">
                                    <h3>
                                      {" "}
                                      {articleData?.data?.author_detail?.name}
                                    </h3>
                                    {
                                      articleData?.data?.author_detail?.designation !== null && (
                                        <h6>
                                          {
                                            articleData?.data?.author_detail
                                              ?.designation
                                          }
                                        </h6>
                                      )
                                    }
                                    <p>
                                      {articleData?.data?.author_detail?.about}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="share-post text-end">
                                <h5>Share</h5>
                                <ul className="share-post-list d-flex">
                                  <li>
                                    <FacebookShareButton
                                      url={pageURL}
                                      quote={articleData?.data?.sub_title}
                                      title={articleData?.data?.title}
                                    >
                                      <a href="#!" target="_blank">
                                        <i
                                          className="fab fa-facebook-f"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </FacebookShareButton>
                                  </li>
                                  <li>
                                    <TwitterShareButton
                                      url={pageURL}
                                      quote={articleData?.data?.sub_title}
                                      title={articleData?.data?.title}
                                    >
                                      <a href="" target="_blank">
                                        <i
                                          className="fab fa-twitter"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </TwitterShareButton>
                                  </li>
                                  <li>
                                    <LinkedinShareButton
                                      url={pageURL}
                                      quote={articleData?.data?.sub_title}
                                      title={articleData?.data?.title}
                                    >
                                      <a href="" target="_blank">
                                        <i
                                          className="fab fa-linkedin-in"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </LinkedinShareButton>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              } else if (articleData?.data?.post_type === 3) {
                //paid article
                return (
                  <>
                    <div className="content-container">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="news-post-content text-center">
                              <h1>{articleData?.data?.title}</h1>
                              <h4>{articleData?.data?.sub_title}</h4>
                              <div className="news-post-meta d-flex justify-content-between">
                                <span className="news-post-cat">Video </span>
                                <span className="news-post-author">
                                  {articleData?.data?.author_detail?.name}{" "}
                                </span>
                                <span className="news-post-date">
                                  {convertDateFormate(
                                    articleData?.data?.publish_date
                                  )}
                                </span>
                              </div>
                              <div className="post-category-heading">
                                <div className="pch-img">
                                  <img
                                    src={articleData?.data?.subject_image}
                                    alt=""
                                  />
                                </div>
                                <p>{articleData?.data?.subject_name}</p>
                              </div>
                              {userData?.success === true && (
                                <div className="news-post-meta d-flex justify-content-center">
                                  <i
                                    className="fa fa-bookmark"
                                    aria-hidden="true"
                                    type="button"
                                    onClick={() =>
                                      saveArticle(articleData?.data?.id)
                                    }
                                  ></i>
                                  <p
                                    type="button"
                                    onClick={() =>
                                      saveArticle(articleData?.data?.id)
                                    }
                                    style={{ marginLeft: 10 }}
                                  >
                                    Save
                                  </p>
                                </div>
                              )}
                              <div className="podcast-hero-img">
                                <img src={articleData?.data?.image} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--============================== Content End ==============================--> */}
                    {/* <!--============================== Content Start ==============================--> */}
                    <div className="content-container podcast-plyr-container p-0">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            {articleData?.data?.description === "" ? (
                              <div className="post-single block-text">
                                <p>{articleData?.data?.excerpt}</p>

                                <div className="d-flex justify-content-center paywall">
                                  {" "}
                                  <Link
                                    to="/subscription"
                                    className="btn btn-default "
                                  >
                                    SUBSCRIBE
                                  </Link>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="post-single block-text"
                                dangerouslySetInnerHTML={{
                                  __html: articleData?.data?.description,
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <ul className="share-post-list fixed d-none d-xl-flex flex-column">
                          <li>
                            <FacebookShareButton
                              url={pageURL}
                              quote={articleData?.data?.sub_title}
                              title={articleData?.data?.title}
                            >
                              <a href="" target="_blank">
                                <i
                                  className="fab fa-facebook-f"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </FacebookShareButton>
                          </li>
                          <li>
                            <TwitterShareButton
                              url={pageURL}
                              quote={articleData?.data?.sub_title}
                              title={articleData?.data?.title}
                            >
                              <a href="" target="_blank">
                                <i
                                  className="fab fa-twitter"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </TwitterShareButton>
                          </li>

                          <li>
                            <LinkedinShareButton
                              url={pageURL}
                              quote={articleData?.data?.sub_title}
                              title={articleData?.data?.title}
                            >
                              <a href="" target="_blank">
                                <i
                                  className="fab fa-linkedin-in"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </LinkedinShareButton>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <!--============================== Content End ==============================--> */}
                    {/* <!--============================== Content Start ==============================--> */}
                    <div className="content-container">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <ul className="podcast-plyr-list">
                              {articleList.length > 0 &&
                                articleList.map((item, ind) => (
                                  <li className="podcast-plyr-item" key={ind}>
                                    <div className="podcast-plyr-player">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item?.audio_embed_code,
                                        }}
                                      />
                                    </div>
                                    <div className="podcast-plyr-intro">
                                      <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                          <p>{item?.episode_name}</p>
                                        </li>
                                        <li
                                          className="breadcrumb-item active"
                                          aria-current="page"
                                        >
                                          {convertDateFormate(
                                            item?.publish_date
                                          )}
                                          <span className="add-btn">
                                            <img src={plusIcon} alt="" />
                                          </span>
                                        </li>
                                      </ol>
                                      <h3>
                                        <a href="#!">{item?.title}</a>{" "}
                                        <span className="podcast-plyr-duration">
                                          {item?.duration}
                                        </span>
                                      </h3>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                            {articleData?.data?.episodes_data
                              ?.next_page_url && (
                                <>
                                  {articleDataLoad === false ? (
                                    <div className="load-more text-center">
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() =>
                                          onHandleLoadMore(
                                            articleData?.data?.episodes_data
                                              ?.next_page_url
                                          )
                                        }
                                      >
                                        Load more results
                                      </a>
                                    </div>
                                  ) : (
                                    <Loading type={"spin"} color={"#000"} />
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--============================== Content End ==============================--> */}
                    {/* <!--============================== Content Start ==============================--> */}
                    <div className="content-container pb-5">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="end-section">
                              <h5>
                                <span className="left">
                                  <img src={headerShapeRight} alt="" />
                                </span>
                                end
                                <span className="right">
                                  <img src={headerShapeLeft} alt="" />
                                </span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              } else {
                //ARTICLE TYPE
                if (articleData?.data?.category_name === "Photo Essay") {
                  if (!photoEssaySliderMode) {
                    return (<ArticleType articleData={articleData} userData={userData} type="photo" />);
                  }
                } else {
                  <ArticleType articleData={articleData} userData={userData} />;
                }
                return (<ArticleType articleData={articleData} userData={userData} />);
              }
            })()}
            {/* Render Slider Modal only if photoEssaySliderMode is true */}
            {photoEssaySliderMode && (
              <Slider slides={convertDataToSlides(articleData)} onClose={() => setPhotoEssaySliderMode(false)} />
            )}

            {
              articleData?.data?.category_name === "Photo Essay" && (
                <>  

                  {/**circular floating button play with blackbg and white foreground*/}
                  <div className={photoEssaySliderMode ? "floating-btn essayMode" : "floating-btn"} onClick={() => setPhotoEssaySliderMode(!photoEssaySliderMode)}>
                    {photoEssaySliderMode ? <i className="fa fa-square" aria-hidden="true"></i> : <i class="fa fa-expand" aria-hidden="true"></i>}
                  </div>
                </>
              )
            }

          </>
          {/* <!--============================== Content End ==============================--> */}
        </main>
      )}

      {/* <!--============================== Main End ==============================--> */}
      {/* <!--============================== Footer Start ==============================--> */}
      <Footer />
      {/* <!--============================== Footer End ==============================--> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  articleData: state.getArticle.data,
  articleDataLoad: state.getArticle.load,
  saveArticleData: state.saveArticle.data,
  userData: state.login.user,
});

const mapDispatchToProps = (dispatch) => ({
  articleDSPCH: (data) => {
    dispatch(ActionCreators.getArticle(data));
  },
  saveArticleDSPCH: (data) => {
    dispatch(ActionCreators.saveArticle(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Article);
