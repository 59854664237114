import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
} from "react-share";

import headerShapeRight from "../../assets/images/header-shape-right.svg";
import headerShapeLeft from "../../assets/images/header-shape-left.svg";
import { convertDateFormate, fixedHeader } from "../../values/utils";

import { Link, useParams } from "react-router-dom";
import BottomPanel from "./BottomPanel";
import LoginPopUp from "../popups/LoginPopUp";
import { useState } from "react";

const ArticleType = ({ articleData, userData, saveArticle, type = "article" }) => {
    const pageURL = window.location.href;

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    const toggleLoginModal = () => {
        setIsLoginModalOpen(!isLoginModalOpen);
        const navbarCollapse = document.getElementById('mainNav');
        if (navbarCollapse.classList.contains('show')) {
            navbarCollapse.classList.remove('show');
            navbarCollapse.classList.add('collapse'); // Manually collapse the navbar
        }

        const mainCollapsed = document.getElementById('mainCollapsed');
        if (!mainCollapsed.classList.contains('show')) {
            mainCollapsed.classList.add('collapsed');
        }

    };


    return (
        <>
            <div className="content-container article-single-container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="div d-flex flex-column-reverse flex-md-column">
                                <div className="news-post-content text-center">
                                    <h1>{articleData?.data?.title}</h1>
                                    <h4>{articleData?.data?.sub_title}</h4>
                                    <div className="news-post-meta d-flex justify-content-between">
                                        <span className="news-post-cat">
                                            {articleData?.data?.category_name}{" "}
                                        </span>
                                        <span className="article-post-by">
                                            by <span>{articleData?.data?.author_detail?.name}{" "}</span>
                                        </span>

                                        <span className="news-post-date">
                                            {convertDateFormate(
                                                articleData?.data?.publish_date
                                            )}
                                        </span>
                                    </div>
                                    <div className="post-category-heading">
                                        <div className="pch-img">
                                            <img
                                                src={articleData?.data?.subject_image}
                                                alt=""
                                            />
                                        </div>
                                        <p>{articleData?.data?.subject_name}</p>
                                    </div>
                                </div>
                                {userData?.success === true && (
                                    <div className="news-post-save d-flex justify-content-center">
                                        <i
                                            className="fa fa-bookmark mt-1"
                                            aria-hidden="true"
                                            type="button"
                                            onClick={() =>
                                                saveArticle(articleData?.data?.id)
                                            }
                                        ></i>
                                        <p
                                            type="button"
                                            onClick={() =>
                                                saveArticle(articleData?.data?.id)
                                            }
                                            style={{ marginLeft: 10 }}
                                        >
                                            Save
                                        </p>
                                    </div>
                                )}
                                {
                                    type === "article" ? (
                                        articleData?.data?.image_credit ? (
                                            <div className="article-post-single-thumbnail">
                                                <figure>
                                                    <img
                                                        className="d-none d-md-block"
                                                        src={articleData?.data?.image}
                                                        alt=""
                                                    />
                                                    <img
                                                        className="d-block d-md-none"
                                                        src={articleData?.data?.image}
                                                        alt=""
                                                    />
                                                    <figcaption className="mb-0">
                                                        {articleData?.data?.image_credit}
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        ) : (
                                            <div className="article-post-single-thumbnail">
                                                <img
                                                    className="d-none d-md-block"
                                                    src={articleData?.data?.image}
                                                    alt=""
                                                />
                                                <img
                                                    className="d-block d-md-none"
                                                    src={articleData?.data?.image}
                                                    alt=""
                                                />
                                            </div>
                                        )
                                    ) :
                                        (
                                            <></>
                                        )
                                }
                            </div>
                            {
                                console.log(articleData?.data)
                            }
                            {articleData?.data?.articleAccessType === "paid" ? (
                                <>
                                    <div className="post-single block-text">
                                        <div
                                            className="post-single block-text"
                                            dangerouslySetInnerHTML={{
                                                __html: articleData?.data?.description,
                                            }}
                                        />

                                    </div>
                                    <div className="d-flex justify-content-center paywall">
                                        {" "}
                                        <p>Please</p>
                                        <Link
                                            to="/subscription"
                                            className="header-subscribe-btn d-md-block"
                                        >
                                            &nbsp;<u>Subscribe</u>&nbsp;
                                        </Link>
                                        <p>or</p>
                                        <div
                                            onClick={toggleLoginModal}
                                        >
                                            <Link
                                                to=""
                                                className="header-login-btn d-md-block"
                                            >
                                                &nbsp;<u>Sign In</u>&nbsp;
                                            </Link>
                                        </div>
                                        <p>to read the full article</p>
                                    </div>
                                    <div>
                                        {/*Pay and read button */}
                                        <div className="">
                                            <div className="d-flex justify-content-center">
                                                <Link

                                                    to=""
                                                    className="btn btn-default"
                                                >
                                                    Read Full Article at just ₹50 <i className="fa fa-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <BottomPanel />
                                    {isLoginModalOpen && (
                                        <div className="popUp" id="popUp">
                                            <LoginPopUp closeModal={toggleLoginModal} />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div
                                    className="post-single block-text"
                                    dangerouslySetInnerHTML={{
                                        __html: articleData?.data?.description,
                                    }}
                                />
                            )}
                            <ul className="share-post-list fixed d-none d-xl-flex flex-column">
                                <li>
                                    <FacebookShareButton
                                        url={pageURL}
                                        quote={articleData?.data?.sub_title}
                                        title={articleData?.data?.title}
                                    >
                                        <a href="" target="_blank">
                                            <i
                                                className="fab fa-facebook-f"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </FacebookShareButton>
                                </li>
                                <li>
                                    <TwitterShareButton
                                        url={pageURL}
                                        quote={articleData?.data?.sub_title}
                                        title={articleData?.data?.title}
                                    >
                                        <a href="" target="_blank">
                                            <i
                                                className="fab fa-twitter"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </TwitterShareButton>
                                </li>

                                <li>
                                    <LinkedinShareButton
                                        url={pageURL}
                                        quote={articleData?.data?.sub_title}
                                        title={articleData?.data?.title}
                                    >
                                        <a href="" target="_blank">
                                            <i
                                                className="fab fa-linkedin-in"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </LinkedinShareButton>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--============================== Content End ==============================--> */}
            {/* <!--============================== Content Start ==============================--> */}
            <div className="content-container pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="end-section">
                                <h5>
                                    <span className="left">
                                        <img src={headerShapeRight} alt="" />
                                    </span>
                                    end
                                    <span className="right">
                                        <img src={headerShapeLeft} alt="" />
                                    </span>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--============================== Content End ==============================--> */}
            {/* <!--============================== Content Start ==============================--> */}
            <div className="content-container pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="post-author-container d-flex flex-wrap">
                                <div className="post-author-content">
                                    <h5>Author</h5>
                                    <div className="post-auther">
                                        <Link
                                            to={
                                                "/author/" +
                                                articleData?.data?.author_detail?.slug
                                            }
                                            className="post-auther-img"
                                        >
                                            <img
                                                src={
                                                    articleData?.data?.author_detail?.image
                                                }
                                                alt=""
                                            />
                                        </Link>

                                        <div className="post-author-name">
                                            <Link
                                                to={
                                                    "/author/" +
                                                    articleData?.data?.author_detail?.slug
                                                }
                                                className="post-auther-img"
                                            >
                                                <h3>
                                                    {articleData?.data?.author_detail?.name}
                                                </h3>
                                            </Link>
                                            {
                                                articleData?.data?.author_detail?.designation !== null && (
                                                    <h6>
                                                        {
                                                            articleData?.data?.author_detail
                                                                ?.designation
                                                        }
                                                    </h6>
                                                )
                                            }
                                            <p>
                                                {articleData?.data?.author_detail?.about}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="share-post text-end">
                                    <h5>Share</h5>
                                    <ul className="share-post-list d-flex">
                                        <li>
                                            <FacebookShareButton
                                                url={pageURL}
                                                quote={articleData?.data?.sub_title}
                                                title={articleData?.data?.title}
                                            >
                                                <a href="#!" target="_blank">
                                                    <i
                                                        className="fab fa-facebook-f"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </FacebookShareButton>
                                        </li>
                                        <li>
                                            <TwitterShareButton
                                                url={pageURL}
                                                quote={articleData?.data?.sub_title}
                                                title={articleData?.data?.title}
                                            >
                                                <a href="" target="_blank">
                                                    <i
                                                        className="fab fa-twitter"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </TwitterShareButton>
                                        </li>
                                        <li>
                                            <LinkedinShareButton
                                                url={pageURL}
                                                quote={articleData?.data?.sub_title}
                                                title={articleData?.data?.title}
                                            >
                                                <a href="" target="_blank">
                                                    <i
                                                        className="fab fa-linkedin-in"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </LinkedinShareButton>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ArticleType;